<ea-parallax-base backgroundImage="/assets/images/parallax/sea/sea.jpg">
    <li class="layer" data-depth=".5">
        <div class="bubble1"><img id="bubble-cloud" src="/assets/images/parallax/sea/bubble1.png"></div>
    </li>

    <li class="layer" data-depth=".5">
        <div class="bubble2"><img id="bubble-cloud2" src="/assets/images/parallax/sea/bubble1.png"></div>
    </li>

    <li class="layer" data-depth=".9">
        <div class="bubble3"><img id="bubble-cloud3" src="/assets/images/parallax/sea/bubble1.png"></div>
    </li>
</ea-parallax-base>