import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'ea-blanco',
    template: ``,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class BlancoComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}