import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AnimalPreviewComponent } from './components/animal-preview/animal-preview.component';
 
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule
    ],
    exports: [AnimalPreviewComponent],
    declarations: [AnimalPreviewComponent],
    providers: [],
})
export class AnimalPreviewModule { }
