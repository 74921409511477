import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'under-construction',
    templateUrl: 'under-construction.component.html'
})

export class UnderConstructionComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}