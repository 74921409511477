import { IAnimal } from '../modules/models/animal.interface';
import { AnimalGroups } from './animal-groups';

/**
 * African animals
 */
const africa: IAnimal[] = [
    {
        id: 1,
        name: 'lion',
        imagesPaths: ['assets/images/animals/africa/lion.png'],
        group: AnimalGroups.AFRICA,
        videoPath: 'https://player.vimeo.com/external/461856844.sd.mp4?s=95633e3aad437fd9fb8b0217c8f5ba9a72807455&profile_id=165&oauth2_token_id=57447761',
        animalSound: 'assets/sounds/animals/africa/lion.mp3',
    },
    {
        id: 2,
        name: 'hippopotamus',
        imagesPaths: ['assets/images/animals/africa/hippopotamus.png'],
        group: AnimalGroups.AFRICA,
        videoPath: 'https://player.vimeo.com/external/453972330.sd.mp4?s=dab1620ddd9f5002977a30eaba58569721ef31dd&profile_id=164&oauth2_token_id=57447761'
    },
    {
        id: 3,
        name: 'african-elephant',
        imagesPaths: ['assets/images/animals/africa/african-elephant.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 4,
        name: 'zebra',
        imagesPaths: ['assets/images/animals/africa/zebra.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 5,
        name: 'rhino',
        imagesPaths: ['assets/images/animals/africa/rhino.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 6,
        name: 'gnu',
        imagesPaths: ['assets/images/animals/africa/gnu.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 7,
        name: 'gorilla',
        imagesPaths: ['assets/images/animals/africa/gorilla.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 8,
        name: 'chimpanzee',
        imagesPaths: ['assets/images/animals/africa/chimpanzee.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 9,
        name: 'two-humped-camel',
        imagesPaths: ['assets/images/animals/africa/two-humped-camel.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 10,
        name: 'chita',
        imagesPaths: ['assets/images/animals/africa/chita.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 11,
        name: 'vulture',
        imagesPaths: ['assets/images/animals/africa/vulture.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 12,
        name: 'tiger',
        imagesPaths: ['assets/images/animals/africa/tiger.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 13,
        name: 'crocodile',
        imagesPaths: ['assets/images/animals/africa/crocodile.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 14,
        name: 'ostrich',
        imagesPaths: ['assets/images/animals/africa/ostrich.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 15,
        name: 'mongoose',
        imagesPaths: ['assets/images/animals/africa/mongoose.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 16,
        name: 'alligator',
        imagesPaths: ['assets/images/animals/africa/alligator.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 17,
        name: 'lemur',
        imagesPaths: ['assets/images/animals/africa/lemur.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 18,
        name: 'one-humped-camel',
        imagesPaths: ['assets/images/animals/africa/one-humped-camel.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 19,
        name: 'giraffe',
        imagesPaths: ['assets/images/animals/africa/giraffe.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 20,
        name: 'gazelle',
        imagesPaths: ['assets/images/animals/africa/gazelle.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 21,
        name: 'warthog',
        imagesPaths: ['assets/images/animals/africa/warthog.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 22,
        name: 'flamingo',
        imagesPaths: ['assets/images/animals/africa/flamingo.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 23,
        name: 'hyena',
        imagesPaths: ['assets/images/animals/africa/hyena.png'],
        group: AnimalGroups.AFRICA
    },
    {
        id: 24,
        name: 'african-wild-dog',
        imagesPaths: ['assets/images/animals/africa/african-wild-dog.png'],
        group: AnimalGroups.AFRICA
    },
];

/**
 * Sea Animals
 */
const sea: IAnimal[] = [

    {
        id: 100,
        name: 'orca',
        imagesPaths: ['assets/images/animals/sea/orca.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 101,
        name: 'lionfish',
        imagesPaths: ['assets/images/animals/sea/lionfish.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 102,
        name: 'sea-turtle',
        imagesPaths: ['assets/images/animals/sea/sea-turtle.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 103,
        name: 'shark',
        imagesPaths: ['assets/images/animals/sea/shark.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 104,
        name: 'star',
        imagesPaths: ['assets/images/animals/sea/starfish.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 105,
        name: 'bluewhale',
        imagesPaths: ['assets/images/animals/sea/bluewhale.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 105,
        name: 'crab',
        imagesPaths: ['assets/images/animals/sea/crab.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 106,
        name: 'dolphin',
        imagesPaths: ['assets/images/animals/sea/dolphin.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 107,
        name: 'jellyfish',
        imagesPaths: ['assets/images/animals/sea/jellyfish.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 108,
        name: 'lobster',
        imagesPaths: ['assets/images/animals/sea/lobster.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 109,
        name: 'narwhal',
        imagesPaths: ['assets/images/animals/sea/narwhal.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 110,
        name: 'octopus',
        imagesPaths: ['assets/images/animals/sea/octopus.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 111,
        name: 'oyster-clam',
        imagesPaths: ['assets/images/animals/sea/oyster-clam.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 112,
        name: 'sea-anemone',
        imagesPaths: ['assets/images/animals/sea/sea-anemone.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 113,
        name: 'sea-cucumber',
        imagesPaths: ['assets/images/animals/sea/sea-cucumber.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 114,
        name: 'seahorse',
        imagesPaths: ['assets/images/animals/sea/seahorse.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 115,
        name: 'seashell-pearl',
        imagesPaths: ['assets/images/animals/sea/seashell-pearl.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 116,
        name: 'sea-snake',
        imagesPaths: ['assets/images/animals/sea/sea-snake.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 117,
        name: 'sea-urchin',
        imagesPaths: ['assets/images/animals/sea/sea-urchin.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 118,
        name: 'sponge',
        imagesPaths: ['assets/images/animals/sea/sponge.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 119,
        name: 'squid',
        imagesPaths: ['assets/images/animals/sea/squid.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 120,
        name: 'stingray',
        imagesPaths: ['assets/images/animals/sea/stingray.png'],
        group: AnimalGroups.SEA
    },
    {
        id: 121,
        name: 'swordfish',
        imagesPaths: ['assets/images/animals/sea/swordfish.png'],
        group: AnimalGroups.SEA
    },
];


/**
 * Farm Animals
 */
const farm: IAnimal[] = [
    {
        id: 200,
        name: 'dog',
        imagesPaths: ['assets/images/animals/farm/dog.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 201,
        name: 'cat',
        imagesPaths: ['assets/images/animals/farm/cat.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 202,
        name: 'rat',
        imagesPaths: ['assets/images/animals/farm/rat.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 203,
        name: 'rabbit',
        imagesPaths: ['assets/images/animals/farm/rabbit.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 204,
        name: 'swan',
        imagesPaths: ['assets/images/animals/farm/swan.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 205,
        name: 'calf',
        imagesPaths: ['assets/images/animals/farm/calf.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 206,
        name: 'cow',
        imagesPaths: ['assets/images/animals/farm/cow.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 207,
        name: 'bull',
        imagesPaths: ['assets/images/animals/farm/bull.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 208,
        name: 'hedgehog',
        imagesPaths: ['assets/images/animals/farm/hedgehog.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 209,
        name: 'mole',
        imagesPaths: ['assets/images/animals/farm/mole.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 210,
        name: 'goatling',
        imagesPaths: ['assets/images/animals/farm/goatling.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 211,
        name: 'goat',
        imagesPaths: ['assets/images/animals/farm/goat.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 212,
        name: 'billy-goat',
        imagesPaths: ['assets/images/animals/farm/billy-goat.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 213,
        name: 'frog',
        imagesPaths: ['assets/images/animals/farm/frog.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 214,
        name: 'lamb',
        imagesPaths: ['assets/images/animals/farm/lamb.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 215,
        name: 'sheep',
        imagesPaths: ['assets/images/animals/farm/sheep.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 216,
        name: 'ram',
        imagesPaths: ['assets/images/animals/farm/ram.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 217,
        name: 'pig',
        imagesPaths: ['assets/images/animals/farm/pig.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 218,
        name: 'chick',
        imagesPaths: ['assets/images/animals/farm/chick.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 219,
        name: 'chicken',
        imagesPaths: ['assets/images/animals/farm/chicken.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 220,
        name: 'ruster',
        imagesPaths: ['assets/images/animals/farm/ruster.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 221,
        name: 'foals',
        imagesPaths: ['assets/images/animals/farm/foals.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 222,
        name: 'horse',
        imagesPaths: ['assets/images/animals/farm/horse.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 223,
        name: 'donkey',
        imagesPaths: ['assets/images/animals/farm/donkey.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 224,
        name: 'duckling',
        imagesPaths: ['assets/images/animals/farm/duckling.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 225,
        name: 'duck',
        imagesPaths: ['assets/images/animals/farm/duck.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
    {
        id: 226,
        name: 'ghoose',
        imagesPaths: ['assets/images/animals/farm/ghoose.png'],
        group: AnimalGroups.FARM_ANIMALS
    },
];



export const animalList: IAnimal[] = [...africa, ...sea, ...farm];
