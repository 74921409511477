
export enum AnimalGroups {
    AFRICA = "africa",
    SEA = "sea",
    EUROPE = "europe",
    ASIA = "asia",
    AUSTRALIA = "australia",
    NORTH_AMERICA = "north-america",
    SOUTH_AMERICA = "south-america",
    FARM_ANIMALS = "farm",
    BUGS = "bugs"
};


export const animalGroupLinks: { group: string, label: string, isActive: boolean }[] = [
    {
        group: AnimalGroups.AFRICA,
        label: 'Africa',
        isActive: true
    },
    {
        group: AnimalGroups.SEA,
        label: 'Sea',
        isActive: true
    },
    {
        group: AnimalGroups.EUROPE,
        label: 'Europe',
        isActive: false
    },
    {
        group: AnimalGroups.ASIA,
        label: 'Asia',
        isActive: false
    },
    {
        group: AnimalGroups.AUSTRALIA,
        label: 'Australia',
        isActive: false
    },
    {
        group: AnimalGroups.NORTH_AMERICA,
        label: 'North America',
        isActive: false
    },
    {
        group: AnimalGroups.SOUTH_AMERICA,
        label: 'South America',
        isActive: false
    },
    {
        group: AnimalGroups.FARM_ANIMALS,
        label: 'Farm',
        isActive: true
    },
    {
        group: AnimalGroups.BUGS,
        label: 'Bugs',
        isActive: false
    },
];