import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ea-games-info',
    templateUrl: 'games-info.component.html'
})

export class GamesInfoComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}