<nav class=" light-blue darken-4">
    <div class="nav-wrapper container">
        <a class="sidenav-link" style="display: block!important;" [routerLink]="['/games']">
            <i class="fas fa-undo"></i>
        </a>
        <a class="brand-logo center">{{'puzzle' | titlecase }}</a>
    </div>
</nav>

<div class="container" style="padding-top: 30px">
    <div class="row">
        <div class="col s12">

            <div class="row">
                <div class="col s12 center">
                    <a class="btn-floating btn-large waves-effect waves-light margin-r-2"
                        [ngClass]="{red: squareCount == 2, gray: squareCount != 2}" (click)="setPuzzleLevel(2)">2</a>
                    <a class="btn-floating btn-large waves-effect waves-light margin-r-2"
                        [ngClass]="{red: squareCount == 3, gray: squareCount != 3}" (click)="setPuzzleLevel(3)">3</a>
                    <a class="btn-floating btn-large waves-effect waves-light"
                        [ngClass]="{red: squareCount == 4, gray: squareCount != 4}" (click)="setPuzzleLevel(4)">4</a>
                </div>
            </div>

            <div class="puzzle" #puzzle style="width: 100%; max-width:700px; margin: auto; overflow: hidden;">
                <div *ngFor="let listElem of items;let i = index;" cdkDropList [id]="listElem.id"
                    [cdkDropListData]="listElem.list" [cdkDropListConnectedTo]="connectedTo"
                    [cdkDropListSortPredicate]="sortPredicate" cdk [ngStyle]="styleOptions"
                    (cdkDropListDropped)="drop($event)" cdkDropListSortingDisabled>

                    <div class="item-box picture-element {{'data-piece-'+squareCount+'-'+item.item}}"
                        [attr.item]="item.item" [ngStyle]="pictureElementStyle" *ngFor="let item of listElem.list"
                        cdkDrag>
                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                    </div>
                </div>
            </div>

            <div class="center">
                <button class="btn" (click)="newGame()">New Game</button>
            </div>

            <div clas="row">
                <div class="col s4 m2 l2 margin-t-4" *ngFor="let image of images">
                    <img src="/assets/images/{{image}}" class="circle responsive-img z-depth-3" [alt]="image"
                        (click)="setNewImage(image)" [ngClass]="{'selected-image': image == selectedImage}" />
                </div>
            </div>
            
        </div>
    </div>
</div>