import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimalService } from './services/animal.services';
import { AnimalGroupHelperService } from './services/animal-group-helper.service';
import { CongratulationService } from './services/congratulation.service';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [],
    declarations: [],
    providers: [
        AnimalService,
        AnimalGroupHelperService,
        CongratulationService
    ]
})
export class MainModule { }
