

import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class AnimalGroupHelperService {
    constructor() { }

    private _heigth$ = new ReplaySubject<number>(1);

    getHeight(): Observable<number> {
        return this._heigth$;
    }

    setHeight(height: number) {
        this._heigth$.next(height);
    }
}