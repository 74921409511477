import {
  animate,
  query,
  style,
  transition,
  trigger,
  group,
  animateChild
} from "@angular/animations";

/**
 * Return Slide left and right trigger
 */
export function animalRouterAnimation() {
  return trigger("animalRouterAnimation", [

    // Previous, slide left to right to show left page
    transition(":decrement", [
      // set new page X location to be -100%
      query(
        ":enter",
        style({
          position: "absolute",
          width: "100%",
          opacity: 0,
          transform: "translateX(-100%)",
        }),
        { optional: true }
      ),

      group([
        // slide existing page from 0% to 100% to the right
        query(
          ":leave",
          animate(
            "500ms ease",
            style({
              position: "absolute",
              width: "100%",
              opacity: 0,
              transform: "translateX(100%)",
            })
          ),
          { optional: true }
        ),
        // slide new page from -100% to 0% to the right
        query(
          ":enter",
          animate(
            "500ms ease",
            style({
              opacity: 1,
              transform: "translateX(0%)",
            })
          ),
          { optional: true }
        )
      ])
    ]),

    // Next, slide right to left to show right page
    //transition(":increment", [
    transition(":increment", [
      // set new page X location to be 100%
      query(
        ":enter",
        style({
          position: "absolute",
          width: "100%",
          opacity: 0,
          transform: "translateX(100%)",
        }),
        { optional: true }
      ),

      group([
        // slide existing page from 0% to -100% to the left
        query(
          ":leave",
          animate(
            "500ms ease",
            style({
              position: "absolute",
              width: "100%",
              opacity: 0,
              transform: "translateX(-100%)",
            })
          ),
          { optional: true }
        ),
        // slide new page from 100% to 0% to the left
        query(
          ":enter",
          animate(
            "500ms ease",
            style({
              opacity: 1,
              transform: "translateX(0%)",
            })
          ),
          { optional: true }
        )
      ])
    ])
  ]);
}

/**
 * Return fade in trigger 
 * */
export function fadeInAnimation() {
  return trigger('fadeInAnimation', [
    
        // route 'enter' transition
        transition(':enter', [

          // css styles at start of transition
          style({ opacity: 0 }),

          // animation and styles at end of transition
          animate('.3s', style({ opacity: 1 }))
      ])
  ]);
}
