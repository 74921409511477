import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";

import { of } from "rxjs";
import { animalList } from './config/animals';

@Injectable()
export class AnimalPreviewResolver implements Resolve<number> {

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const num = animalList.find(x => x.name === route.params.animal).id;

    console.log('num:', num);
    return of(num);
  }
}