import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ea-btn-nav-background',
    templateUrl: 'btn-background.component.html'
})

export class BtnNavBackgroundComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}