import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AnimalPreviewModule } from '@app/modules/common/animal-preview/animal-preview.module';

import { AnimalGroupComponent } from './animal-group.component';
import { ParallaxSeaComponent } from './components/parallax-sea/parallax-sea.component';
import { ParallaxBaseComponent } from './components/parallax-base/parallax-comon.component';
import { ParallaxFarmComponent } from './components/parallax-farm/parallax-farm.component';
import { ParallaxAsiaComponent } from './components/parallax-asia/parallax-asia.component';
import { ParallaxBugsComponent } from './components/parallax-bugs/parallax-bugs.component';
import { BtnNavBackgroundComponent } from './components/btn-background/btn-background.component';
import { ParallaxAfricaComponent } from './components/parallax-africa/parallax-africa.component';
import { ParallaxAustraliaComponent } from './components/parallax-australia/parallax-australia.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        AnimalPreviewModule
    ],
    exports: [
        AnimalGroupComponent
    ],
    declarations: [
        AnimalGroupComponent,
        ParallaxBaseComponent,
        ParallaxSeaComponent,
        ParallaxAfricaComponent,
        ParallaxFarmComponent,
        ParallaxAsiaComponent,
        ParallaxBugsComponent,
        ParallaxAustraliaComponent,
        BtnNavBackgroundComponent
    ],
    providers: [],
})
export class AnimalsGroupModule { }
