import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AnimalRouterModule } from "@app/animal-router.module";
import { ConfettiModule } from "@app/modules/common/confetti/confeti.module";
import { FooterModule } from "@app/modules/common/footer/footer.module";
import { HeaderModule } from "@app/modules/common/header/header.module";
import { MainWrapperComponent } from "./main-wrapper.component";

@NgModule({
  imports: [
    CommonModule,
    AnimalRouterModule,

    // Common
    HeaderModule,
    FooterModule,
    ConfettiModule,
  ],
  declarations: [
    MainWrapperComponent,
  ],
  exports: [
    MainWrapperComponent,
  ]
})
export class MainWrapperModule { }