import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';

declare const M: any;

@Component({
    selector: 'ea-header',
    templateUrl: 'header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HeaderComponent implements OnInit, AfterViewInit {

    constructor(
        private _translateService: TranslateService,
        private _changeDetection: ChangeDetectorRef) { }

    sidenavInstance: any;
    showAdminLink = !environment.production;

    ngAfterViewInit(): void {
        const elems = document.getElementById('sidenavigation');
        this.sidenavInstance = M.Sidenav.init(elems, {});
    }

    ngOnInit() { }
    
    closeSidenav() {
        this.sidenavInstance.close();
    }

    setLangTo(lang: string) {
        // this._translateService.setDefaultLang(lang);
        this._translateService.use(lang);
        this._changeDetection.detectChanges();
    }
}