<nav>
    <div class="nav-wrapper container">
        <a data-target="sidenavigation" class="sidenav-trigger"><i class="fas fa-bars"></i></a>
        <a [routerLink]="['/']" routerLinkActive="router-link-active" class="brand-logo center"><i
                class="fas fa-paw fa-2x"></i></a>
        <ul id="nav-mobile" class="left hide-on-med-and-down">
            <li><a href="#" [routerLink]="['/games']" routerLinkActive="active" translate>PAGES.GAMES</a></li>
            <li><a href="#" [routerLink]="['/animals/africa']" routerLinkActive="active" translate>PAGES.ANIMALS</a></li>
            <li><a href="#" [routerLink]="['/about']" routerLinkActive="active" translate>PAGES.ABOUT</a></li>
            <li><a href="#" [routerLink]="['/contact']" routerLinkActive="active" translate>PAGES.CONTACT</a></li>
        </ul>
        <!-- <ul id="nav-mobile" class="right hide-on-med-and-down">
            <li><a href="#" [routerLink]="['/admin']" routerLinkActive="active" *ngIf="showAdminLink">Admin</a></li>
        </ul> -->
        <ul id="nav-mobile" class="right hide-on-med-and-down">
            <li><a (click)="setLangTo('rs-cir')">Rs(Cir)</a></li>
            <li><a (click)="setLangTo('rs-lat')">Rs(Lat)</a></li>
            <li><a (click)="setLangTo('en')">En</a></li>
        </ul>
    </div>
</nav>

<div class="sidenav" id="sidenavigation">
    <ul class="collection">
        <li class="collection-header center"><h4><i class="fas fa-paw"></i> Explore animals</h4></li>
        <li><a href="#" [routerLink]="['/games']" (click)="closeSidenav()"><i class="fas fa-gamepad fa-3x"></i> Games</a></li>
        <li><a href="#" [routerLink]="['/animals/africa']" (click)="closeSidenav()"><i class="fas fa-search fa-3x"></i> Animals</a></li>
        <li><a href="#" [routerLink]="['/about']" (click)="closeSidenav()"><i class="far fa-address-card fa-3x"></i> About</a></li>
        <li><a href="#" [routerLink]="['/contact']" (click)="closeSidenav()"><i class="far fa-envelope fa-3x" ></i> Contact</a></li>
    </ul>
</div>