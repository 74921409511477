import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { animalGroupLinks, AnimalGroups } from 'src/app/config/animal-groups';
import { animalList } from 'src/app/config/animals';
import { IAnimal } from '../../models/animal.interface';
import { underscore } from 'underscore';

declare var _: underscore;

@Injectable()
export class AnimalService {

    constructor() { }

    getAnimalGroups(): Observable<any[]> {
        // return of([
        //     AnimalGroups.AFRICA,
        //     AnimalGroups.ASIA,
        //     AnimalGroups.AUSTRALIA,
        //     AnimalGroups.EUROPE,
        //     AnimalGroups.SEA,
        //     AnimalGroups.SOUTH_AMERICA,
        //     AnimalGroups.NORTH_AMERICA,
        //     AnimalGroups.FARM_ANIMALS,
        //     AnimalGroups.BUGS,
        // ]);
        return of(_.sortBy(animalGroupLinks, function(g) { return g.group; }));
    }

    /**
     * Get list of all animals
     */
    getAnimals(): IAnimal[] {
        return [...animalList];
    }

    getShuffledAnimas(): IAnimal[] {
        return _.shuffle(this.getAnimals());
    }

    /**
     * Get list of animals for specific group
     * @param group 
     */
    getAnimalsForGroup(group: string) {
        const searchebleGroup = group.toLowerCase();

        var list = animalList.filter(x => {
            var res = x.group.toLowerCase() == searchebleGroup;

            return res;
        });

        return list;
    }

    /**
     * Get first animal from selected group
     * @param group 
     */
    getFirstAnimalForGroup(group: string) {
        return this.getAnimalsForGroup(group)[0];
    }

    /**
     * Get animal by given name
     * @param name 
     */
    getAnimalByName(name: string): IAnimal {
        name = name.toLowerCase();

        return animalList.find(x => x.name.toLowerCase() == name);
    }

    /**
     * Return animal for right navigation
     * @param group 
     * @param animal 
     */
    getRightAnimalFor(group: string, animal: string) {
        const animals = this.getAnimalsForGroup(group);
        animal = animal.toLowerCase();
        const index = animals.findIndex(x => x.name.toLowerCase() == animal);

        if (index < animals.length - 1) {
            return animals[index + 1];
        }

        return animals[0];
    }

    /**
     * Return animal for left navigation
     * @param group 
     * @param animal 
     */
    getLeftAnimalFor(group: string, animal: string) {
        const animals = this.getAnimalsForGroup(group);
        animal = animal.toLowerCase();
        const index = animals.findIndex(x => x.name.toLowerCase() == animal);

        if (index == 0) {
            return animals[animals.length - 1];
        }

        return animals[index - 1];
    }

}