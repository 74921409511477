import { underscore } from 'underscore';
import { ActivatedRoute } from '@angular/router';
import { delay, map, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';

import { IQuestion } from './models/question.interface';
import { IAnimal } from '@app/modules/models/animal.interface';
import { AnimalService } from '@app/modules/main/services/animal.services';
import { CongratulationService } from '@app/modules/main/services/congratulation.service';
import { IOption } from './models/option.interface';

declare var _: underscore;
declare const M: any; // materialize

enum OptionStatus {
    Default = 0,
    Correct = 1,
    Wrong = 2,
}

@Component({
    selector: 'ea-quiz',
    templateUrl: 'quiz.component.html'
})

export class QuizComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute,
        private animalService: AnimalService,
        private _congratulationService: CongratulationService
    ) { }

    gameType: string;
    game$: Observable<IQuestion>;
    newGame$ = new Subject<void>();
    destroy$ = new Subject<void>();
    question$ = new Subject<IQuestion>();
    answer$ = new BehaviorSubject<IAnimal>(null);

    ngOnInit() {
        this.gameType = this.route.snapshot.data.type;

        this._gameSetup();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();

        M.Toast.dismissAll();
    }

    setNewQuestion(): void {
        this.question$.next(this._createQuestion());
    }

    answerQuestion(animal: IAnimal) {
        this.answer$.next(animal);
    }

    private _gameSetup() {

        this.newGame$.pipe(
            takeUntil(this.destroy$),
            delay(2000)
        ).subscribe(x => {
            this.setNewQuestion();
        });

        this.game$ = combineLatest([this.question$, this.answer$]).pipe(
            map(([question, answer]) => {
                return this._handleQuestionResult(question, answer);
            })
        );

        setTimeout(() => this.setNewQuestion(), 1);
    }

    private _handleQuestionResult(question, answer): IQuestion {
        if (!answer) {
            return question;
        }

        if (this._isAnswerAlreadySelected(question, answer)) {
            return question;
        }

        question.alredyAnswered.push(answer);

        if (this._isAnswerCorrect(question, answer)) {
            this._gameFinished(question, answer);
        }
        else {
            this._markOption(question, answer, OptionStatus.Wrong);
        }

        return question;
    }

    private _gameFinished(question, answer) {
        question.isAnswered = true;
        this._markOption(question, answer, OptionStatus.Correct);

        this._congratulationService.congrads();

        this.answer$.next(null);
        setTimeout(() => this.newGame$.next(), 500);
    }

    private _markOption(question, answer, status: OptionStatus) {
        question.options.find(x => x.id === answer.id).status = status;
    }

    private _isAnswerCorrect(question, answer): boolean {
        return question.question.id === answer.id;
    }

    private _isAnswerAlreadySelected(question, answer): boolean {
        return question.alredyAnswered.findIndex(x => x.id === answer.id) >= 0;
    }

    private _createQuestion(): IQuestion {
        const animals = this.animalService.getAnimals();
        const misteryAnimal = this._getRandomAnimal(animals);

        const quizOptions = this._getQuizOptions(animals, misteryAnimal);

        const question = {
            question: misteryAnimal,
            options: quizOptions,
            isAnswered: false,
            alredyAnswered: []
        } as IQuestion;

        return question;
    }

    private _getQuizOptions(animals: IAnimal[], quizAnswer: IAnimal): IOption[] {
        const options = this._getAnimalsForQuiz(animals, 3, quizAnswer);

        return options.map(x => {
            return {
                ...x,
                status: OptionStatus.Default
            }
        });
    }

    private _getRandomAnimal(animals: IAnimal[]): IAnimal {
        return animals[Math.floor(Math.random() * animals.length)];
    }

    private _getAnimalsForQuiz(elements: any[], numberOFOptions: number, alreadyTakenElement?: IAnimal): IAnimal[] {

        const filteredElements = elements.filter(x => x.id !== alreadyTakenElement.id);
        const shuffledElements = _.shuffle(filteredElements);
        const chossenElements = _.first(shuffledElements, numberOFOptions);
        const result = _.shuffle([alreadyTakenElement, ...chossenElements]);

        return result;
    }
}
