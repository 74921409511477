import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

declare let gtag: Function;
// declare const M: any; // if needed for modules from materializecss

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  title = 'animals';

  constructor(
    public router: Router,
    public translate: TranslateService
  ) {

    /**
     * Set google analytics
     */
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-FYG8JFCGT9',
          {
            'page_path': event.urlAfterRedirects,
            'cookie_domain': 'explore-animals.com',
            'cookie_flags': 'SameSite=None;Secure'
          }
        );
      }
    });

    /**
     * Set languages
     */
    translate.addLangs(['en', 'rs-lat', 'rs-cir']);
    translate.setDefaultLang('rs-cir');
    translate.use('rs-cir');


    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

}
