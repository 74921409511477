import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { GamesComponent } from './games.component';
import { QuizComponent } from './quiz/quiz.component';
import { PuzzleComponent } from './puzzle/puzzle.component';
import { GamesInfoComponent } from './games-info.component';
import { MemoryGameComponent } from './memory/memory-game.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,
        DragDropModule,
    ],
    exports: [],
    declarations: [
        GamesInfoComponent,
        MemoryGameComponent,
        PuzzleComponent,
        QuizComponent,
        GamesComponent
    ],
    providers: [],
})
export class GamesModule { }
