import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfettiComponent } from './components/confetti.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [ConfettiComponent],
    declarations: [ConfettiComponent],
})
export class ConfettiModule { }
