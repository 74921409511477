import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { underscore } from 'underscore';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ICard } from './models/card-interface';
import { AnimalService } from '@app/modules/main/services/animal.services';
import { CongratulationService } from '@app/modules/main/services/congratulation.service';
import { IAnimal } from '@app/modules/models/animal.interface';

declare var _: underscore;

@Component({
    selector: 'ea-memory-game',
    templateUrl: 'memory-game.component.html',
    styleUrls: ['memory-game.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MemoryGameComponent implements OnInit, OnDestroy {

    constructor(
        private _animalSerice: AnimalService,
        private _changeDetection: ChangeDetectorRef,
        private _congratulationService: CongratulationService
    ) { }

    cards: ICard[];
    flipped = false;
    pair: ICard[] = [];
    numberOfPairs = 6;

    destroy$ = new Subject();
    newGame$ = new BehaviorSubject(null);

    ngOnInit() {
        this.newGame$.pipe(
            takeUntil(this.destroy$),
        ).subscribe(() => this._createGame());
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    flip(card: ICard) {
        if (!this.canCardBeFlipped(card)) {
            return;
        }

        this._flipCard(card);

        if (!this._isPairSelected(this.pair)) {
            return;
        }

        this._checkSelectedPairs();

        this._checkGameEnd();

        this._changeDetection.detectChanges();
    }

    private _flipCard(card) {
        this.pair.push(card);
        card.flipped = true;
    }

    private _isPairSelected(pair: ICard[]): boolean {
        return pair.length == 2;
    }

    private canCardBeFlipped(card: ICard) {
        return !(card.guessted || card.flipped || this.pair.length === 2);
    }

    private _checkSelectedPairs() {
        if (this.pair[0].animal.id !== this.pair[1].animal.id) {
            const pair = this.pair;

            setTimeout(() => {
                this._resetPair(pair);
            }, 1000);
        } else {
            this.pair = [];
        }
    }

    private _checkGameEnd() {
        if (this.cards.filter(x => x.flipped == false).length === 0) {
            this._congratulationService.congrads();

            setTimeout(() => {
                this._resetAllCards();
            }, 1000);

            setTimeout(() => {
                this.newGame$.next(null);
            }, 2000);
        }
    }

    private _resetAllCards() {
        this.cards.forEach(x => x.flipped = false);

        this._changeDetection.detectChanges();
    }

    private _createGame(): void {

        const animals = this._animalSerice.getShuffledAnimas();

        const animalsForPairs = _.first(animals, this.numberOfPairs);

        const cardsWithoutPairs = this.getCards(animalsForPairs);

        const fullDeck = [...cardsWithoutPairs, ...JSON.parse(JSON.stringify(cardsWithoutPairs))];

        this.cards = _.shuffle(fullDeck);

        this._changeDetection.detectChanges();
    }

    private getCards(animals: IAnimal[]): ICard[] {
        return animals.map(animal => this.createCard(animal));
    }

    private createCard(animal: IAnimal): ICard {
        return {
            flipped: false,
            guessted: false,
            animal: { ...animal }
        };
    }

    private _resetPair(pair) {
        pair[0].flipped = false;
        pair[1].flipped = false;

        this.pair = [];

        this._changeDetection.detectChanges();
    }
}
