import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Observable } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';
import { animalRouterAnimation } from 'src/app/common/animations';
import { AnimalService } from '../../main/services/animal.services';
import { IAnimal } from '../../models/animal.interface';

declare const M: any;

@Component({
    selector: 'ea-animal-group',
    templateUrl: 'animal-group.component.html',
    animations: [animalRouterAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AnimalGroupComponent implements OnInit, AfterViewInit {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private animalService: AnimalService
    ) { }

    animals: IAnimal[];
    group$: Observable<string>;
    animalSidenavInstance: any;
    animalGroupsSidenavInstance: any;
    animalGroups$: Observable<any[]>;

    ngOnInit() {
        this.animalGroups$ = this.animalService.getAnimalGroups();

        this.group$ = this.route.params.pipe(
            pluck('group'),
            tap(g => this.animals = this.animalService.getAnimalsForGroup(g))
        );
    }

    ngAfterViewInit() {
        const animalElements = document.getElementById('animal-sideview');
        this.animalSidenavInstance = M.Sidenav.init(animalElements, { preventScrolling: false });

        const groupElemenets = document.getElementById('animal-groups-sideview');
        this.animalGroupsSidenavInstance = M.Sidenav.init(groupElemenets, { preventScrolling: false, edge: 'right' });
    }

    getRouteAnimation(outlet: RouterOutlet): number {
        try {
            const res =
                outlet.activatedRouteData.num === undefined
                    ? -1
                    : outlet.activatedRouteData.num;

            if (res == -1) {
                const group = outlet.activatedRoute.snapshot.params.group;
                const firstAnimal = this.animalService.getFirstAnimalForGroup(group);
                this.router.navigate([`animals/${group}`, firstAnimal.name]);
            }

            return res;
        }
        catch (error) {
            console.log('ERROR ', error);
            return -1;
        }
    }

    closeAnimalSidenav() {
        this.animalSidenavInstance.close();
    }

    closeAnimalGroupSidenav() {
        this.animalGroupsSidenavInstance.close();
    }
}
