<div class="root-content">

    <ea-header></ea-header>

    <div class="main-content">
        <ea-confetti></ea-confetti>

        <router-outlet #o="outlet"></router-outlet>
    </div>

    <ea-footer></ea-footer>

</div>