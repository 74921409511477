<div *ngIf="animalName$ | async as i" style="position: absolute;
width: 100%;">

    <div class="fluent-container">
        <div class="row">
            <div class="center title-chains">
                <span class="title-board">
                    {{ i   | titlecase }}
                </span>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col s12">
                <div>
                    <div class="center">
                        <img #animalImage class="responsive-img animal-image" [src]="animal.imagesPaths[0]"
                            (load)="animalLoaded()">
                    </div>
                    <div class="center" *ngIf="animal.videoPath">
                        <!-- @todo set video player -->
                    </div>
                </div>

                <div class="row">
                    <div class="col s6">
                        <a class="btn-floating btn-large btn-nav-image waves-effect waves-light white"
                            [routerLink]="['/animals', group, leftAnimal.name]">
                            <img class="responsive-img circle " [src]="leftAnimal.imagesPaths[0]">
                        </a>
                    </div>
                    <div class="col s6 right-align">
                        <a class="btn-floating btn-large btn-nav-image waves-effect waves-light white"
                            [routerLink]="['/animals', group, rightAnimal.name]">
                            <img class="responsive-img circle " [src]="rightAnimal.imagesPaths[0]">
                        </a>
                    </div>
                </div>

                <!-- @todo Create information bar -->

                <!-- 
                <ul class="collection">
                    <li class="collection-item"><i class="fas fa-ruler-horizontal"></i> : 2.5-3 m</li>
                    <li class="collection-item"><i class="fas fa-weight-hanging"></i> : 180-255 Kg</li>
                    <li class="collection-item"><i class="fas fa-utensils"></i> :
                        <i class="fas fa-drumstick-bite text-teal lighten-1"></i>
                    </li>
                    <li class="collection-item"><i class="fas fa-tachometer-alt"></i> : 55 Km/h</li>
                    <li class="collection-item"><i class="fas fa-map-marked-alt"></i> : Africa</li>
                </ul> -->

            </div>
        </div>
    </div>

</div>