import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { routes } from './config/routes.config';
 
import { CustomRouteReuseStategy } from './custom-reuse.strategy';
 
@NgModule({
    imports: [
       RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: [],
    providers: [
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStategy
        }
    ]
})
export class AnimalRouterModule { }
