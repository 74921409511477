import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild } from "@angular/core";
import { AnimalGroupHelperService } from '@app/modules/main/services/animal-group-helper.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare const Parallax: any;

@Component({
    selector: 'ea-parallax-base',
    templateUrl: 'parallax-base.component.html'
})
export class ParallaxBaseComponent implements AfterViewInit, OnDestroy {

    constructor(
        private _animalsGroupHelperService: AnimalGroupHelperService,
        private _renderer: Renderer2
    ) {
    }

    bgImage: any = {};

    @Input() set backgroundImage(imageUrl: string) {
        this.bgImage = {
            'background-image': ' url(\'' + imageUrl + '\')' 
        }
    };

    _destroy$ = new Subject<void>();
    @ViewChild('parallaxContainer') parallaxContainer: ElementRef;

    ngAfterViewInit(): void {
        const scene = document.getElementById('scene');
        const parallax = new Parallax(scene);

        this._animalsGroupHelperService.getHeight()
            .pipe(
                takeUntil(this._destroy$)
            )
            .subscribe(height => {
                this._adjustHeight(height);
            });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    private _adjustHeight(height: number) {
        console.log('adjust size to ' + height + 'px');

        this._renderer.setStyle(this.parallaxContainer.nativeElement, "height", height + "px");
    }
}