import {
    ActivatedRouteSnapshot,
    RouteReuseStrategy,
    DetachedRouteHandle
} from "@angular/router";
import { AnimalGroupComponent } from './modules/application/animals/animal-group.component';

export class CustomRouteReuseStategy implements RouteReuseStrategy {

    handlers: { [key: string]: DetachedRouteHandle } = {};

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.data.shouldReuse || false;
    }

    store(route: ActivatedRouteSnapshot, handle: {}): void {
        if (route.data.shouldReuse && this.getUrl(route)) {
            this.handlers[this.getUrl(route)] = handle;
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!this.handlers[this.getUrl(route)];
    }

    retrieve(route: ActivatedRouteSnapshot): any {
        if (!this.getUrl(route)) {
            return null;
        }
        return this.handlers[this.getUrl(route)];
    }

    shouldReuseRoute(
        future: ActivatedRouteSnapshot,
        curr: ActivatedRouteSnapshot
    ): boolean {

        const res = (
            future.routeConfig === curr.routeConfig &&
            JSON.stringify(future.params) === JSON.stringify(curr.params)
        );

        // properly reload component when query property change
        if (curr.component == AnimalGroupComponent && future.component == AnimalGroupComponent) {
            return true
        }

        return res;
    }

    getUrl(route: ActivatedRouteSnapshot) {

        const parentUrls = route.parent.url.map(x => x.toString());
        const routeUrls = route.url.map(x => x.toString());

        const parentUrlsJoin = parentUrls.join('/');
        const routeUrlsJoin = routeUrls.join('/');

        if (!parentUrlsJoin || !routeUrlsJoin) {
            return null;
        }

        let url = "";
        if (parentUrlsJoin) {
            url += parentUrlsJoin + "/";
        }

        if (routeUrlsJoin) {
            url += routeUrlsJoin;
        }

        return url === "" ? null : url;
    }
}
