import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ea-main-wrapper-component',
    templateUrl: 'main-wrapper.component.html'
})

export class MainWrapperComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}