import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class CongratulationService {

    private congrads$ = new Subject<null>();

    getCongrads(): Observable<null> {
        return this.congrads$;
    }

    congrads() {
        this.congrads$.next();
    }
}
