import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'ea-footer',
    templateUrl: 'footer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class FooterComponent implements OnInit {
    constructor() { }

    year: number;

    ngOnInit() {
        const date = new Date();
        this.year = date.getFullYear();
    }
}