<nav class=" light-blue darken-4">
    <div class="nav-wrapper container">
        <a class="sidenav-link" style="display: block!important;" [routerLink]="['/games']">
            <i class="fas fa-undo"></i>
        </a>
        <a class="brand-logo center">{{gameType | titlecase }}</a>
    </div>
</nav>

<div class="container" style="padding-top: 30px">
    <div *ngIf="(game$ | async) as game">
        <div class="row center" *ngIf="gameType === 'shadow'">
            <img [src]="game.question.imagesPaths[0]" class="quiz-question" width="200" height="200" alt="Guess animal?"
                [ngClass]="{'transition': game.isAnswered === true}">
            <h4 class="quiz-question-title" [ngClass]="{'transition': game.isAnswered === true}">
                {{game.question.name | titlecase}}
            </h4>
        </div>

        <div class="row center" *ngIf="gameType === 'quiz'">
            <h4 class="">
                {{game.question.name | titlecase}}
            </h4>
            <button class="btn-floating waves-effect waves-light red">
                <i class="fas fa-volume-up"></i>
            </button>
        </div>

        <div class="quiz-options-container row">
            <div *ngFor="let option of game.options" class="col s6 l3 center">
                <img [src]="option.imagesPaths[0]" width="200" height="200" (click)="answerQuestion(option)"
                    class="image-option z-depth-3"
                    [ngClass]="{'image-option-wrong': option.status == 2, 'image-option-correct': option.status == 1}" />
            </div>
        </div>
    </div>
</div>