import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TranslationsLoaderService {

    constructor(
        // tslint:disable-next-line: variable-name
        private _httpClient: HttpClient
    ) { }

    static createTranslateLoader(http: HttpClient) {
        return new TranslateHttpLoader(http, './assets/i18n/', '.json');
    }

    async translationsLoaderFactory(translate: TranslateService, injector: Injector, customVersion?: string) {
        await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        let lang = 'rs=lat';

        if (customVersion) {
            lang += `.${customVersion.toLowerCase()}`;
        }

        console.log('Translate: ', lang);

        const translations = await this._httpClient.get('./assets/i18n/en.json').toPromise();
        translate.setTranslation(lang, translations, true);

        if (customVersion) {
            const customTranslations = await this._httpClient.get(`./assets/i18n/${lang}.json`).toPromise();
            translate.setTranslation(lang, customTranslations, true);
        }

        // set default language
        translate.setDefaultLang(lang);

        try {
            await translate.use(lang).toPromise();
        } catch (err) { }
    }

}
