export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyAeDvsZFWIh5zzgxuGyx8k7c5oGNR7twAM",
    authDomain: "explore-animals.firebaseapp.com",
    projectId: "explore-animals",
    storageBucket: "explore-animals.appspot.com",
    messagingSenderId: "868876963949",
    appId: "1:868876963949:web:ce323c74a2bfcc470cef64",
    measurementId: "G-4WKLNKGW2Q"
  }
};
