import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CongratulationService } from '@app/modules/main/services/congratulation.service';

import { confetti, ConfettiConfig } from 'dom-confetti';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ea-confetti',
  templateUrl: 'confetti.component.html',
  styleUrls: ['confetti.component.scss'],
  animations: [
    trigger(
      'myAnimation', [
      transition(':enter', [
        style({ transform: 'scale(0)', opacity: 0 }),
        animate('200ms', style({ transform: 'scale(1)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', 'opacity': 1 }),
        animate('500ms', style({ transform: 'scale(0)', opacity: 0 }))
      ])
    ]
    )
  ],
})

export class ConfettiComponent implements OnInit, OnDestroy {

  show = false;

  config: ConfettiConfig = {
    angle: 30,
    spread: 720,
    startVelocity: 16,//20,
    elementCount: 700,
    dragFriction: 0.05,
    duration: 2000,
    stagger: 5,
    width: "30px",
    height: "30px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  destroy$ = new Subject<null>();

  @ViewChild('confettiDiv', { read: ElementRef }) confettiElement: ElementRef;

  constructor(
    private _congratulationService: CongratulationService,
    private _changeDetection: ChangeDetectorRef
  ) {
    console.log('service: ', this._congratulationService);
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this._congratulationService.getCongrads().pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      console.log('trigger catched');
      this.displayConfetti();
    });
  }

  displayConfetti() {
    this._changeDetection.detectChanges();
    this.show = true;

    setTimeout(() => {
      confetti(this.confettiElement.nativeElement, this.config);
    }, 300);

    setTimeout(() => {
      this.show = false;
      this._changeDetection.detectChanges();
    }, 3000);
  }
}
