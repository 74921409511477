<nav *ngIf="group$ | async as group" class=" light-blue darken-4 z-depth-3">
    <div class="nav-wrapper container">
        <a data-target="animal-sideview" class="sidenav-trigger" style="display: block!important;"><i
                class="fas fa-hippo"></i></a>
        <a class="brand-logo center">{{group | titlecase }}</a>
        <a data-target="animal-groups-sideview" class="sidenav-trigger right" style="display: block!important;"><i
                class="fas fa-layer-group"></i></a>
    </div>
</nav>

<div class="sidenav" id="animal-sideview">

    <h3 class="p2">Animals</h3>
    <ul class="collection" *ngIf="group$ | async as group">
        <li>
            <div class="animals-sidebar-images">
                <a *ngFor="let animal of animals" (click)="closeAnimalSidenav()"
                    [routerLink]="['/animals', group, animal.name]">
                    <img class="circle responsive-img z-depth-2"
                        style="max-height: 60px; max-width: 60px; border:1px solid gray; margin: 3px;"
                        [src]="animal.imagesPaths[0]" />
                </a>
            </div>
        </li>
    </ul>
</div>

<div class="sidenav" id="animal-groups-sideview">

    <h3 class="p2">Groups</h3>
    <ul class="collection">
        <li *ngFor="let group of animalGroups$ | async" class="collection-item">
            <a href="#" (click)="closeAnimalGroupSidenav()" [routerLink]="['/animals', group.group | lowercase]"
                *ngIf="group.isActive">{{group.label}}</a>
            <span class="disabled-link" *ngIf="!group.isActive">{{group.label}}</span>
        </li>
    </ul>
</div>

<div style="position: relative;">

    <ng-container *ngIf="group$ | async as group">
        <ea-parallax-sea *ngIf="group === 'sea'" class="parallax-container-item"></ea-parallax-sea>
        <ea-parallax-africa *ngIf="group === 'africa'" class="parallax-container-item"></ea-parallax-africa>
        <ea-parallax-farm *ngIf="group === 'farm animals'" class="parallax-container-item"></ea-parallax-farm>
        <ea-parallax-asia *ngIf="group === 'asia'" class="parallax-container-item"></ea-parallax-asia>
        <ea-parallax-australia *ngIf="group === 'australia'" class="parallax-container-item"></ea-parallax-australia>
        <ea-parallax-bugs *ngIf="group === 'bugs'" class="parallax-container-item"></ea-parallax-bugs>
    </ng-container>

    <div class="container">
        <div class="content animal-preview-wrapper" style="z-index: 2;" [@animalRouterAnimation]="getRouteAnimation(router)">
            <router-outlet #router="outlet"></router-outlet>
        </div>
    </div>

</div>