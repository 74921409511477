import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, tap } from "rxjs/operators";
import { Observable } from 'rxjs';

import { IAnimal } from '@app/modules/models/animal.interface';
import { AnimalService } from '@app/modules/main/services/animal.services';
import { AnimalGroupHelperService } from '@app/modules/main/services/animal-group-helper.service';

declare const M: any;

@Component({
    selector: 'ea-animal-preview',
    templateUrl: 'animal-preview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AnimalPreviewComponent implements OnInit, AfterViewInit {

    constructor(
        private _route: ActivatedRoute,
        private _animalService: AnimalService,
        private _animalGroupHelperService: AnimalGroupHelperService
    ) { }

    modal: any;
    group: string;
    animal: IAnimal;
    leftAnimal: IAnimal;
    rightAnimal: IAnimal;
    animalName$: Observable<IAnimal>;

    @ViewChild('animalImage') imageContainer: ElementRef;

    ngOnInit() {
        this.group = this._route.parent.snapshot.params.group;

        this.animalName$ = this._route.params.pipe(
            map(x => x.animal),
            tap(x => this.animal = this._animalService.getAnimalByName(x)),
            tap(x => {
                this.leftAnimal = this._animalService.getLeftAnimalFor(this.group, x);
                this.rightAnimal = this._animalService.getRightAnimalFor(this.group, x);
            })
        );
    }

    ngAfterViewInit() {
        const elems = document.getElementById('animalPreviewVideo');

        this.modal = M.Modal.init(elems, {
            onCloseStart: this.stopVideo.bind(this),
            onCloseEnd: this.stopVideo.bind(this)
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this._adjustHeight(this.imageContainer.nativeElement.offsetWidth);
    }

    playAudio(src: string) {
        let audio = new Audio();
        audio.src = src;
        audio.load();
        audio.play();
    }

    stopVideo() {
    }

    /**
     * Stop video
     * @param  {Element} element The element that contains the video
     */
    stopVideoFromElement(element: Element) {

    };

    animalLoaded() {
        this._adjustHeight(this.imageContainer.nativeElement.offsetWidth);
    }

    private _adjustHeight(height: number) {
        this._animalGroupHelperService.setHeight(height + 95);
    }
}