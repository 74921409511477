import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AnimalRouterModule } from './animal-router.module';
import { environment } from 'src/environments/environment.prod';
import { AnimalPreviewResolver } from './animal-preview.resolver';

import { AppComponent } from './app.component';
import { GoogleAnalyticsService } from './google-analytics.service';
import { AboutComponent } from './modules/application/about/about.component';
import { ContactComponent } from './modules/application/contact/contact.component';
import { HomepageComponent } from './modules/application/homepage/homepage.component';
import { BlancoComponent } from './modules/common/blanko/blanko.component';
import { FooterComponent } from './modules/common/footer/footer.component';
import { HeaderComponent } from './modules/common/header/header.component';
import { MainModule } from './modules/main/main.module';

import { PuzzleElementDirective } from './common/puzzle-element.directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationsLoaderService } from './modules/main/services/translation.service';
import { GamesModule } from './modules/application/games/games.module';
import { AnimalsGroupModule } from './modules/application/animals/animals-group.module';
import { ConfettiModule } from './modules/common/confetti/confeti.module';
import { MainWrapperComponent } from './modules/application/main-wrapper/main-wrapper.component';
import { UnderConstructionComponent } from './modules/application/under-construction/under-construction.component';
import { HeaderModule } from './modules/common/header/header.module';
import { MainWrapperModule } from './modules/application/main-wrapper/main-wrapper.module';
import { RouterModule } from '@angular/router';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  declarations: [
    AppComponent,

    // directives
    PuzzleElementDirective,
  ],
  imports: [
    CommonModule,
    MainModule,
    HttpClientModule,
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    ConfettiModule,
    MainWrapperModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (TranslationsLoaderService.createTranslateLoader),
        deps: [HttpClient]
      },
    }),
    GamesModule,
    AnimalsGroupModule,
    ConfettiModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    AnimalPreviewResolver,
    GoogleAnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
