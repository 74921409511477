import { AfterViewInit, ChangeDetectionStrategy, ElementRef, Renderer2, Component, HostListener, OnInit, ViewChild, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { underscore } from 'underscore';

import { PuzzleHolder } from './models/puzzle-holder.model';
import { PuzzleElementDirective } from '@app/common/puzzle-element.directive';
import { CongratulationService } from '@app/modules/main/services/congratulation.service';

declare const _: underscore;
const numberList = _.range(1, 17);

@Component({
    selector: 'ea-puzzle',
    templateUrl: 'puzzle.component.html',
    styleUrls: ['./puzzle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PuzzleComponent implements OnInit, AfterViewInit {

    constructor(
        private renderer: Renderer2,
        private _changeDetection: ChangeDetectorRef,
        private _congratulationService: CongratulationService
    ) { }


    squareCount = 2;
    connectedTo = [];
    styleOptions: any;
    selectedImage = '';
    pictureElementStyle: any = {};
    images = [
        "random.jpg",
        'elephant.jpg',
        'rino.jpg',
        'owl.jpg',
        'crocodile.jpg',
        'rabbit.jpg',
        'hippo.jpg',
        'lion.jpg',
        'wolf.jpg',
        'chita.jpg',
        'girafe.jpg',
        'gorila.jpg'
    ];

    currentImage: string = this.images[0];

    @ViewChild('puzzle') imageContainer: ElementRef;
    @ViewChildren(PuzzleElementDirective, { read: ElementRef }) puzzleElements: ElementRef[];

    items: PuzzleHolder[] = [];

    puzzleItemsInitial = numberList.map((x, i) => ({ item: ++i }));

    puzzleHoldersInitials = numberList.map(x => ({
        id: `square-${x}`,
        value: x,
        list: []
    }));

    ngOnInit() {
        this.setImage(this.currentImage);
        this._setStyles();
        this._setRandomElements();
        this._setConnectTo();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this._adjustHeight();
        }, 100);
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                0,
                0);
            transferArrayItem(event.container.data,
                event.previousContainer.data,
                1,
                0);
            this._checkValues();
        }
    }

    setNewImage(image: string) {
        this.currentImage = image;
        this.setImage(image);
        this._setRandomElements();
    }

    setImage(image: string) {
        this.selectedImage = image;

        if (image.startsWith('random')) {
            const images = this.images.slice(1);
            image = _.shuffle(images)[0];
            this.pictureElementStyle = { ...this.pictureElementStyle, 'background-image': ' url(\'/assets/images/' + image + '\')' };
        } else {
            this.pictureElementStyle = { ...this.pictureElementStyle, 'background-image': ' url(\'/assets/images/' + image + '\')' };
        }
    }

    setPuzzleLevel(level: 2 | 3 | 4) {
        this.squareCount = level;
        this._setStyles();
        this._setRandomElements();
        this._setConnectTo();
    }

    sortPredicate(index: number, item: CdkDrag<number>) {
        return index == 0;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this._adjustHeight();
    }

    newGame() {
        this._setRandomElements();
        this._setConnectTo();
    }

    private _setStyles() {
        this.pictureElementStyle = {
            ...this.pictureElementStyle,
            'background-size': (100 * this.squareCount) + '%',
        };

        this.styleOptions = {
            width: (100 / this.squareCount) + '%',
            height: (100 / this.squareCount) + '%',
            float: 'left',
            overflow: 'hidden'
        }
    }

    private _checkValues() {
        if (this._isPuzzleDone()) {
            this._congratulationService.congrads();

            setTimeout(() => {
                this._setRandomElements();
                this._setConnectTo();
                this._changeDetection.detectChanges();
            }, 2000);
        }
    }

    private _isPuzzleDone() {
        let isDone = true;

        this.items.forEach(item => {
            const puzzlePiece = item.list[0];

            if (item.value !== puzzlePiece.item) {
                isDone = false;
            }
        });

        return isDone;
    }

    private _adjustHeight() {
        this.renderer.setStyle(this.imageContainer.nativeElement, "height", this.imageContainer.nativeElement.offsetWidth + "px");
    }

    private _setConnectTo() {
        this.connectedTo = [];
        for (let listItem of this.items) {
            this.connectedTo.push(listItem.id);
        };
    }

    private _setRandomElements() {
        this.setImage(this.selectedImage);

        const puzzleSize = this.squareCount;

        let puzzleElements = [...this.puzzleItemsInitial].slice(0, Math.pow(puzzleSize, 2));
        const puzzleHolders = [...this.puzzleHoldersInitials].slice(0, Math.pow(puzzleSize, 2));

        puzzleElements = _.shuffle(puzzleElements);

        const newList = [];

        puzzleHolders.forEach(holder =>
            newList.push({
                id: holder.id,
                value: holder.value,
                list: [puzzleElements.pop()]
            })
        );

        this.items = [...newList];
    }
}
