<nav class=" light-blue darken-4">
    <div class="nav-wrapper container">
        <a class="sidenav-link" style="display: block!important;" [routerLink]="['/games']">
            <i class="fas fa-undo"></i>
        </a>
        <a class="brand-logo center">{{'memory' | titlecase }}</a>
    </div>
</nav>

<div class="container">
    <div class="row" style="margin-top: 49px" *ngIf="cards">

        <div class="col s6 m3 margin-b-1 center" *ngFor="let card of cards; let i = index">
            <div class="card-box scene--card">
                <div class="swap-card  card-shadow" [ngClass]="{'is-flipped': card.flipped}" (click)="flip(card)">
                    <div class="card__face card__face--front">
                        <i class="fas fa-paw fa-2x"></i>
                        <span style="position: absolute; bottom: 1px; left: 5px;">{{i+1}}</span>
                    </div>
                    <div class="card__face card__face--back" style="position: relative; padding: 10px;">
                        <img [src]="card.animal.imagesPaths[0]" width="100%">
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>