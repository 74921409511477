import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ea-games',
    templateUrl: 'games.component.html'
})

export class GamesComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}