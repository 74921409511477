import { Routes } from '@angular/router';
import { AnimalPreviewResolver } from '@app/animal-preview.resolver';
import { BlancoComponent } from '@app/modules/common/blanko/blanko.component';
import { AboutComponent } from '../modules/application/about/about.component';
import { QuizComponent } from '../modules/application/games/quiz/quiz.component';
import { GamesComponent } from '@app/modules/application/games/games.component';
import { ContactComponent } from '../modules/application/contact/contact.component';
import { HomepageComponent } from '../modules/application/homepage/homepage.component';
import { GamesInfoComponent } from '@app/modules/application/games/games-info.component';
import { PuzzleComponent } from '@app/modules/application/games/puzzle/puzzle.component';
import { AnimalGroupComponent } from '../modules/application/animals/animal-group.component';
import { MemoryGameComponent } from '@app/modules/application/games/memory/memory-game.component';
import { AnimalPreviewComponent } from '@app/modules/common/animal-preview/components/animal-preview/animal-preview.component';
import { MainWrapperComponent } from '@app/modules/application/main-wrapper/main-wrapper.component';
import { UnderConstructionComponent } from '@app/modules/application/under-construction/under-construction.component';

export const routes: Routes = [
    {
        path: 'under',
        component: UnderConstructionComponent
    },
    {
        path: '',
        component: MainWrapperComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('@app-modules/homepage/home-page.module').then(x => x.HomePageModule),
                data: { animation: 'homepage' }
            },
            {
                path: 'about',
                loadChildren: () => import('@app-modules/about/about-page.module').then(x => x.AboutPageModule),
                data: { animation: 'about' }
            },
            {
                path: 'contact',
                loadChildren: () => import('@app-modules/contact/contact-page.module').then(x => x.ContactPageModule),
                data: { animation: 'contact' }
            },
            {
                path: 'games',
                component: GamesComponent,
                children: [
                    {
                        path: '',
                        component: GamesInfoComponent
                    },
                    {
                        path: 'quiz',
                        component: QuizComponent,
                        data: {
                            type: 'quiz'
                        }
                    },
                    {
                        path: 'shadow',
                        component: QuizComponent,
                        data: {
                            type: 'shadow'
                        }
                    },
                    {
                        path: 'memory',
                        component: MemoryGameComponent
                    },
                    {
                        path: 'puzzle',
                        component: PuzzleComponent
                    }
                ]
            },
            {
                path: 'animals/:group',
                component: AnimalGroupComponent,
                data: {
                    detatch: true
                },
                children: [
                    {
                        path: ':animal',
                        component: AnimalPreviewComponent,
                        resolve: {
                            num: AnimalPreviewResolver
                        }
                    },
                    {
                        path: '',
                        component: BlancoComponent,
                        canLoad: []
                    }
                ]
            },
            {
                path: 'admin',
                loadChildren: () => import('@app-modules/admin/admin.module').then(x => x.AdminModule),
                canActivate: [] // todo create guard
            },
        ]
    },
    {
        path: '**',
        redirectTo: ''
    }
];