<nav class=" light-blue darken-4">
    <div class="nav-wrapper container">
        <a class="brand-logo center">{{'games' | titlecase }}</a>
    </div>
</nav>

<div class="container" style="margin-top: 40px;">
    <div class="row center">

        <div class="col s6 m4 offset-m1">
            <div class="card" style="padding-bottom: 10px; padding-top: 10px;">
                <a href="" class="margin-b-1" [routerLink]="['memory']">
                    <p><i class="fas fa-th fa-7x"></i></p>
                    <h4>Memorija</h4>
                </a>
            </div>

        </div>

        <div class="col s6 m4 offset-m2">
            <div class="card" style="padding-bottom: 10px; padding-top: 10px;">
                <a href="" class="margin-b-1" [routerLink]="['quiz']">
                    <p><i class="fas fa-feather-alt fa-7x"></i></p>
                    <h4>Kviz</h4>
                </a>
            </div>            
        </div>

        <div class="col s6 m4 offset-m1">
            <div class="card" style="padding-bottom: 10px; padding-top: 10px;">
                <a href="" class="margin-b-1" [routerLink]="['shadow']">
                    <p><i class="fas fa-disease fa-7x"></i></p>
                    <h4>Oblici</h4>
                </a>
            </div> 
        </div>

        <div class="col s6 m4 offset-m2">
            <div class="card" style="padding-bottom: 10px; padding-top: 10px;">
                <a href="" class="margin-b-1" [routerLink]="['puzzle']">
                    <p><i class="fas fa-puzzle-piece fa-7x"></i></p>
                    <h4>Slagalica</h4>
                </a>
            </div> 
        </div>
    </div>
</div>